<template>
  <div class="full-container">
    <div class="setting-container">
      <div class="flex nowrap" v-show="false">
        <md-field>
          <md-field-item
            title="服务："
            :content="selectorValue"
            arrow
            solid
          />
        </md-field>
        <md-selector
          v-model="isSelectorShow"
          :default-value="serviceId"
          :data="serviceData"
          max-height="220px"
          title="请选择服务"
          large-radius
        ></md-selector>
      </div>
      <div class="flex nowrap setting-item" v-show="false">
        <p class="setting-tips">原锁号：</p>
        <div class="flex-no-wrap fields-item">
          <input
            type="number"
            class="fields-input"
            v-model="lockNo"
            @input="checkLength('lockNo')"
            :disabled="true"
          />
          <md-icon
            name="scan"
            size="lg"
            @click="controlScanModal(true, 'er')"
          ></md-icon>
        </div>
        <scan
          v-if="isErScan"
          :code-type="'er'"
          @closeScan="controlScanModal(false, 'er')"
          @getCode="getScanLockCode"
        />
      </div>
      <div class="flex nowrap setting-item">
        <p class="setting-tips">IMEI：</p>
        <div class="flex-no-wrap fields-item">
          <input
            type="number"
            class="fields-input"
            v-model="imei"
            @input="checkLength('imei')"
          />
          <md-icon
            name="scan"
            size="lg"
            @click="useAndroidScan"
          ></md-icon>
        </div>
      </div>
      <div class="flex nowrap setting-item">
        <p class="setting-tips">锁号：</p>
        <div class="flex-no-wrap fields-item">
          <input
              type="number"
              class="fields-input"
              v-model="newLockNo"
              @input="checkLength('lockNo')"
          />
          <md-icon
              name="scan"
              size="lg"
              @click="controlScanModal(true, 'er')"
          ></md-icon>
        </div>
        <scan
            v-if="isErScan"
            :code-type="'er'"
            @closeScan="controlScanModal(false, 'er')"
            @getCode="getScanLockCode"
        />
      </div>
      <div class="flex nowrap">
        <md-field>
          <md-field-item
              title="服务："
              :content="selectorNewValue"
              @click="showNewSelector"
              arrow
              solid
          />
        </md-field>
        <md-selector
            v-model="isNewSelectorShow"
            :default-value="serviceNewId"
            :data="serviceData"
            max-height="220px"
            title="请选择服务"
            large-radius
            @choose="onNewSelectorChoose"
        ></md-selector>

      </div>
      <div class="flex setting-item" style="border: none;">
<!--        <md-agree-->
<!--            v-model="isAutoRecord"-->
<!--            :size="'lg'"-->
<!--            @change="onAutoChange('isAutoRecord', isAutoRecord, $event)"-->
<!--        ><p style="font-size: 0.4rem;">自动维护</p></md-agree>-->
        <md-radio name="2" v-model="isAutoRecord" size="lg" label="短接设置" inline/>
        <md-radio name="1" v-model="isAutoRecord" size="lg" label="自动维护" inline/>
      </div>

      <!-- 添加设置 -->
      <md-button
        type="primary"
        size="small"
        class="setting-one-add-btn"
        @click="confirmBegin"
        >设置</md-button>


    </div>
    <div class="version">版本：{{ $version }}</div>
  </div>
</template>

<script>
import TitleHeader from "../../components/TitleHeader";
import Scan from "../../components/Scan";
import { Dialog, Toast } from "mand-mobile";
import apiConfig from "../../assets/config/api.config";
import axios from "axios";
import Api from "../../assets/config/api.config";

export default {
  name: "setting",
  components: {
    TitleHeader,
    Scan,
  },
  data() {
    return {
      staff: 0,
      isErScan: false,
      isBarScan: false,
      lockNo: "",
      newLockNo: "",
      imei: "",
      isSelectorShow: false,
      isNewSelectorShow: false,
      selectorValue: "无",
      selectorNewValue: "无",
      serviceId: "0",
      serviceNewId: "0",
      serviceList: [], // 需要增加一个IP项
      serviceData: [
        {value: "0", text: '无'}
      ],
      isProgress: false,
      checkTimer: null,
      functionValue: 3,
      isAutoRecord: "2",
    };
  },
  created() {
    document.title = '设置ID'
    this.getServiceList();
    this.lockNo = this.$route.query.sn || '';
    this.staff = this.$route.query.staff || 0;
    window.onScanSuccess = (...arg)=> this.onScanSuccess(...arg); // 果然需要进行挂载！！！
  },
  methods: {
    // 自动维护
    onAutoChange() {
      // 默认回填一个锁号
      if(!this.lockNo) {
        this.lockNo = '99' + this.imei.slice(2, 4) + this.imei.slice(8, 14);
        this.addLockRecord();
      }

    },
    // 清空成功记录
    clearHistory() {
      this.lockNo = '';
      this.serviceId = '0';
      this.selectorValue = "无";
    },
    // 向永久化服务增加一条记录
    addLockRecord() {
      if(!this.serviceId) {
        Toast.info('请选择服务类型');
      }
      let params = {
        staffId: '88888',
        imei: this.imei,
        lockNo: this.lockNo,
        serviceId: parseInt(this.serviceId) || 1,
        statusCode: 100  // 表示只是插入记录，并没有状态显示
      }
      axios.post(apiConfig.saveSetting, params).then(res => {
        if(res.data) {
          Toast.info('维护成功')
        }
      }).catch(err => {
        if(err && err.response) {
          if(err.response.status === 401) {
            this.autoLogin(this.getServiceList);
          }
        }
      })
    },
    // 根据锁号查询IMEI
    getIMEIByLockNo() {
      let params = {
        imei: this.imei,
        statusCode: 200
      }
      axios.get(Api.getSettingHistory, {params: params}).then(res => {
        if(res.data.code) {
          if(res.data.data.records.length > 0) {
            // this.imei = res.data.data.records[0].imei;
            this.lockNo = res.data.data.records[0].lockNo;
            this.serviceId = res.data.data.records[0].serviceId.toString();
            this.selectorValue = this.serviceData[res.data.data.records[0].serviceId].text;
          }else {
            if(parseInt(this.isAutoRecord) === 1 ) {
              this.onAutoChange();
            }else {
              Toast.info('无设置记录，请联系维护或自动维护');
            }
          }
        }else {
          Toast.info(res.data.msg);
        }
      })
    },
    // 在设置IP和更换锁号的过程中，先要下发指令
    dealCMD() {
      Toast.loading('指令下发中，请稍后');
      let ip = parseInt(this.serviceId) !== 0 ? this.serviceList[parseInt(this.serviceId) - 1].ip : '101.37.116.116:4050' // 默认生产
      let params = {
        sn: this.lockNo,
        url: 'http://' + ip + '/4gplock/cmd'
      };
      axios.post(apiConfig.dealDirection, params).then(res => {
        if(res.data.errNo === 0) {
          Toast.info('下发指令成功')
          this.addOneService();
        }else {
          Toast.info(res.data.errMsg);
        }
      }).catch(err => {
        Toast.failed(err);
      })
    },
    // 存储设置成功的结果到数据库
    saveSettingResult(status) {
      let params = {
        staffId: this.staff,
        imei: this.imei,
        lockNo: this.functionValue === 3 ? this.newLockNo : this.lockNo,
        serviceId: parseInt(this.serviceNewId),
        statusCode: status  // 只有成功的状态才保存
      }
      axios.post(apiConfig.saveSetting, params).then(res => {
        console.log('数据保存成功')
      })
    },
    // 根据返回的cmdId 检查是否添加成功
    checkSettingSuccess(cmdId) {
      Toast.loading('设置中，请稍后');
      this.checkTimer = setTimeout(() => {
        let params = {
          CmdId: cmdId,
        };
        axios.post(apiConfig.checkSettingSuccess, params).then((res) => {
          if (res.data.Result.CfgStatus === 0) {
            clearTimeout(this.checkTimer);
            this.checkTimer = null;
            this.checkSettingSuccess(cmdId);
          } else {
            // 表示设置成功
            clearTimeout(this.checkTimer);
            this.checkTimer = null;
            this.saveSettingResult(200);
            // Toast.succeed('设置成功')
            Toast.hide();
            this.clearHistory();
            Dialog.succeed({
              title: '成功',
              content: '设置成功',
              confirmText: '确定',
            })
          }
        }).catch(err => {
            clearTimeout(this.checkTimer);
            this.checkTimer = null;
            this.checkSettingSuccess(cmdId);
        });
      }, 3000);
    },
    addOneService() {
      Toast.loading('设置中，请稍后...');
      let params = {
          imei: this.imei,
          lockNo: this.functionValue === 3 ? this.newLockNo : this.lockNo,
          serviceId: parseInt(this.serviceNewId),
        };
        axios.post(apiConfig.addSetting, params).then(res => {
          if (res.data.StatusCode === 200) {
            this.saveSettingResult(0); // 持久化纪录一下开始设置
            this.checkSettingSuccess(res.data.Result.CmdId);
            Toast.hide();
          }else if(res.data.StatusCode === 1007) {
            Toast.info(res.data.Message + ', 请联系维护');
          }else {
            Toast.info(res.data.Message);
          }
        })
        .catch(err => {
          Toast.info(err);
          if(err.response) {
            if(err.response.status === 401) {
              this.autoLogin(this.addOneService);
            }else {
              Toast.failed('设置失败，重试中')
            }
          }
        })
    },
    // 巡检员自动登陆
    autoLogin(callback) {
      let params = new FormData();
      params.append('username', 'ctp_inspector_01');
      params.append('password', 'abc12345678');
      axios.post(apiConfig.login, params, this.$headers).then(res => {
        if(res.data.code === 200) {
          localStorage.setItem('user', 'ctp_inspector_01');
          callback();
        }else {
          Toast.failed(res.data.msg)
        }
      })
    },
    // 获取服务列表
    getServiceList() {
      axios.get(apiConfig.getServiceList).then(res => {
        if(res.data) {
          res.data.map(d => {
            switch (d.Id) {
              case 1:
                d.ip = '101.37.116.116:4050';
                break;
              case 2:
                d.ip = '8.133.185.147:4002';
                break;
              case 3:
                d.ip = '8.133.185.147:4016';
                break;
              default:
                break;
            }
            this.serviceData.push({
              value: d.Id,
              text: d.Name.split('_')[0]
            })

          })
          this.serviceList = res.data;
        }
      })
      .catch(err => {
        if(err) {
          this.autoLogin(this.getServiceList);
          if(err.response.status === 401) {
            this.autoLogin(this.getServiceList);
          }
        }

      })
    },
    confirmBegin() {
      if(this.serviceNewId === '0') {
        Toast.info('请选择服务类型');
        return false;
      }
      if(this.newLockNo.length !== 10 || this.imei.length !== 15) {
        Toast.info("请输入正确的锁号或IMEI");
        return false;
      }
      if(parseInt(this.isAutoRecord) === 1) { // 自动维护
        // if(!this.lockNo) {
          this.getIMEIByLockNo()
        // }
        Dialog.confirm({
          title: '确认',
          content: '请确认是否进行设置',
          confirmText: '确定',
          onConfirm: () => {
            this.dealCMD()
          },
        });
      }else if(parseInt(this.isAutoRecord) === 2) { // 短接设置
        this.addOneService();
      }


    },
    // check 锁号或是IMEI的长度
    checkLength(type) {
      if (type === "lockNo") {
        this.newLockNo = this.newLockNo.slice(0, 10)
      }
      if(type === "imei") {
        this.imei = this.imei.slice(0, 15);
        // if(this.imei && this.imei.length === 15) {
        //   this.getIMEIByLockNo();
        // }
      }
    },
    getScanLockCode(code) {
      this.isErScan = false;
      this.newLockNo = this.filterLock(code);
    },
    getScanIMEICode(code) {
      this.isBarScan = false;
      this.imei = code;
    },
    // 从二维码中过滤出锁号
    filterLock(string, params = "lock") {
      if (string) {
        let search = string.split("?")[1];
        return search.replace(params + "=", "");
      }
    },
    addSetting() {
      this.isProgress = false;
      // 判断锁号和IMEI号的格式
      if (this.lockNo.length === 10 && this.imei.length === 15) {
        this.addOneService();
      } else {
        Toast.info("请输入正确的锁号或IMEI");
      }

    },
    // 控制config Modal的显示
    controlScanModal(bool, type) {
      if (type === "er") {
        this.isErScan = bool;
      } else if (type === "bar") {
        this.isBarScan = bool;
      }
    },
    onNewSelectorChoose(option) {
      this.serviceNewId = option.value;
      this.selectorNewValue = option.text;
    },
    showNewSelector() {
      this.isNewSelectorShow = true;
    },
    useAndroidScan(){
      javascript:scanner.startScan('onScanSuccess')
    },
    onScanSuccess(res) {
      this.imei = res.resultStr;
    }
  },
  computed: {
    toggleMultiValue() {
      let imei = this.imei.length;
      let isChecked = this.isAutoRecord;
      return {
        imei,
        isChecked
      }
    }
  },
  watch: {
    toggleMultiValue: function(val) {
      if(val.imei === 15 && parseInt(val.isChecked) === 1) {
        this.getIMEIByLockNo();
      }
    }
  }
};
</script>

<style lang="less" src="./setting.less" ></style>
